import { useQuery } from '@apollo/client';
import { WordPressBlock } from '@faustwp/blocks';
import { AGENTS_DISPLAY_QUERY } from '../../fragments/AgentsDisplay';
import Loading from '../../components/Loading';
import dynamic from 'next/dynamic';

const AgentsDisplay = dynamic(() => import('../../components/AgentsDisplay'));

const AcfAgentsDisplay: WordPressBlock = props => {
	const { loading, error, data } = useQuery(AGENTS_DISPLAY_QUERY);

	if (loading) return <Loading height="200px" />;
	if (error) return <p>Error: {error.message}</p>;

	return <AgentsDisplay data={data.agents.nodes} />;
};

AcfAgentsDisplay.displayName = 'AcfAgentsDisplay';

export default AcfAgentsDisplay;
