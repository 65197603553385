import { FaustTemplate } from '@faustwp/core';
import { gql } from '@apollo/client';
import { GetArchiveQuery } from '../__generated__/graphql';
import PostFeed from '../components/PostFeed';
import SiteWrapper from '../components/SiteWrapper';

const Component: FaustTemplate<GetArchiveQuery> = props => {
	if (!props.data) {
		return <>Error: Data is missing</>;
	}
	return (
		<SiteWrapper siteProps={props.data}>
			<PostFeed
				posts={props.data.posts.nodes}
				blogSettings={props.data.page.blogSettings}
			/>
		</SiteWrapper>
	);
};

Component.query = gql`
	query GetArchive($asPreview: Boolean = false) {
		page(id: 703, idType: DATABASE_ID, asPreview: $asPreview) {
			title
			seo {
				canonical
				title
				metaDesc
				focuskw
				schema {
					pageType
					articleType
					raw
				}
			}
			blogSettings {
				adImage {
					node {
						altText
						sizes
						sourceUrl
						mediaDetails {
							height
							width
						}
						srcSet(size: _2048X2048)
					}
				}
				ctaButtonLabel
				ctaContent
				ctaTitle
				ctaButtonUrl {
					nodes {
						uri
						link
					}
				}
			}
		}
		posts(last: 12) {
			nodes {
				date
				excerpt
				id
				link
				title
				featuredImage {
					node {
						id
						sourceUrl
						altText
						mediaDetails {
							width
							height
						}
						sizes(size: MEDIUM)
					}
				}
				categories {
					nodes {
						categoryId
						name
					}
				}
				tags {
					nodes {
						name
						link
						id
					}
				}
			}
		}
		generalSettings {
			title
			description
		}
		primaryMenuItems: menuItems(where: { location: PRIMARY }, last: 50) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				url
			}
		}
		footerMenuItems: menuItems(where: { location: FOOTER }) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				menu {
					node {
						name
					}
				}
			}
		}
		productMenuItems: menuItems(where: { location: PRODUCT }) {
			nodes {
				id
				uri
				path
				label
				parentId
				cssClasses
				menu {
					node {
						name
					}
				}
				url
			}
		}
		cbaSiteSettings {
			cbaSiteSettingsFields {
				ctaText
				ctaHeader
				ctaContent
				ctaButtonLink {
					nodes {
						uri
						link
					}
				}
				ctaButtonText
				productMenuTitle
				phoneNumber
				address
				ctaLink {
					nodes {
						uri
						link
					}
				}
				socialMedia {
					link
					platform
				}
				copyright
			}
		}
	}
`;

Component.variables = ({}, ctx) => {
	return {
		asPreview: ctx?.asPreview,
	};
};

export default Component;
