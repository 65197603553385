import { gql } from '@apollo/client';

export const AGENTS_DISPLAY_QUERY = gql`
	query GetAgentsQuery {
		agents(
			where: { orderby: { field: MENU_ORDER, order: ASC } }
			last: 100
		) {
			nodes {
				title
				cbaAgents {
					headshot {
						node {
							sourceUrl(size: LARGE)
							srcSet(size: LARGE)
							mediaDetails {
								height
								width
							}
							altText
							sizes(size: LARGE)
						}
					}
					title
					description
				}
			}
		}
	}
`;
