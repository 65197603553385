import { Box, keyframes } from '@chakra-ui/react';
import React from 'react';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

type LoadingProps = {
	height: string;
};

const Loading: React.FC<LoadingProps> = ({ height }) => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height={height}
		>
			<Box
				as="span"
				animation={`${bounce} 1.4s infinite`}
				mx="2px"
				bg="blue.500"
				w="10px"
				h="10px"
				borderRadius="50%"
			></Box>
			<Box
				as="span"
				animation={`${bounce} 1.4s infinite .2s`}
				mx="2px"
				bg="blue.500"
				w="10px"
				h="10px"
				borderRadius="50%"
			></Box>
			<Box
				as="span"
				animation={`${bounce} 1.4s infinite .4s`}
				mx="2px"
				bg="blue.500"
				w="10px"
				h="10px"
				borderRadius="50%"
			></Box>
		</Box>
	);
};

export default Loading;
