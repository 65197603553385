import { Box, Link } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';
import { GetPageQuery, GetPostQuery } from '../../__generated__/graphql';
import { useSiteStore } from '../../hooks/use-site-store';
import SiteHead from '../SiteHead';
import dynamic from 'next/dynamic';

const SiteHeader = dynamic(() => import('../SiteHeader'));
const SiteFooter = dynamic(() => import('../SiteFooter'));

interface SiteWrapperProps {
	siteProps: GetPageQuery | GetPostQuery;
	children: ReactNode;
}

const isGetPageQuery = (
	props: GetPageQuery | GetPostQuery
): props is GetPageQuery => {
	return (props as GetPageQuery).page !== undefined;
};

const SiteWrapper: FC<SiteWrapperProps> = ({ siteProps, children }) => {
	// console.log(siteProps);
	const {
		setMenuItems,
		setSitePhoneNumber,
		setCta,
		setSiteAddress,
		setSeo,
		setPageTitle,
	} = useSiteStore(state => state);

	const menuItems = siteProps.primaryMenuItems.nodes;
	const footerMenuItems = siteProps.footerMenuItems.nodes;
	const productMenuItems = siteProps.productMenuItems.nodes;
	const phoneNumber =
		siteProps.cbaSiteSettings.cbaSiteSettingsFields.phoneNumber;
	const address = siteProps.cbaSiteSettings.cbaSiteSettingsFields.address;
	const ctaText = siteProps.cbaSiteSettings.cbaSiteSettingsFields.ctaText;
	const ctaPhone =
		siteProps.cbaSiteSettings.cbaSiteSettingsFields.ctaLink.nodes[0].uri;

	const productMenuTitle =
		siteProps.cbaSiteSettings.cbaSiteSettingsFields.productMenuTitle;
	const socialMedia =
		siteProps.cbaSiteSettings.cbaSiteSettingsFields.socialMedia;
	const copyright = siteProps.cbaSiteSettings.cbaSiteSettingsFields.copyright;

	const seo = isGetPageQuery(siteProps)
		? siteProps.page?.seo
		: siteProps.post?.seo;
	const pageTitle = isGetPageQuery(siteProps)
		? siteProps.page?.title
		: siteProps.post?.title;

	useEffect(() => {
		setMenuItems(menuItems);
		setSitePhoneNumber(phoneNumber);
		setCta(ctaText, ctaPhone);
		setSiteAddress(address);
		setSeo(seo);
		setPageTitle(pageTitle);
	}, [
		menuItems,
		setMenuItems,
		phoneNumber,
		setSitePhoneNumber,
		ctaText,
		pageTitle,
		ctaPhone,
		setCta,
		address,
		setSiteAddress,
		seo,
		setSeo,
	]);

	return (
		<>
			<SiteHead description={siteProps.generalSettings.description} />
			<Link
				href="#entry-content"
				tabIndex={-1}
				position="absolute"
				top="-40px"
				left="0"
				color="white"
				p={8}
			>
				Skip to content
			</Link>
			<SiteHeader />
			<Box as="main" overflow="hidden" id="entry-content">
				{children}
			</Box>
			<SiteFooter
				footerMenu={footerMenuItems}
				productMenu={productMenuItems}
				productMenuTitle={productMenuTitle}
				socialMedia={socialMedia}
				copyright={copyright}
			/>
		</>
	);
};

export default SiteWrapper;
