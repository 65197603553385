import { Box, Heading } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useSiteStore } from '../../../hooks/use-site-store';
import { useBlocksTheme } from '@faustwp/blocks';
import { CBATheme } from '../../../types/Theme';

const PostBanner: FC = () => {
	const { pageTitle } = useSiteStore(state => state);

	const theme = useBlocksTheme() as unknown as CBATheme;
	return (
		<Box
			// bg={theme.palette.accent}
			pt={{ base: 8, lg: 12 }}
			textAlign={'center'}
			position="relative"
			zIndex="5"
		>
			<Heading
				as="h1"
				color={theme.palette.primary}
				fontSize={theme.fontSizes.xxLarge}
			>
				{pageTitle}
			</Heading>
		</Box>
	);
};

export default PostBanner;
