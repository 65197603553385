import { WordPressBlock } from '@faustwp/blocks';
import { GravityformsForm as GravityformsFormProp } from '../../__generated__/graphql';
import { useQuery } from '@apollo/client';
import { GFORM_QUERY } from '../../fragments/GravityForms';
import Loading from '../../components/Loading';
import dynamic from 'next/dynamic';

type GravityformsFormProps = {
	attributes: {
		formId: string;
	};
};

const GravityFormsDisplay = dynamic(
	() => import('../../components/GravityFormsDisplay')
);

const GravityformsForm: WordPressBlock<GravityformsFormProps> = props => {
	const gformId = props.attributes.formId;

	const { loading, error, data } = useQuery(GFORM_QUERY, {
		variables: {
			gformId,
		},
	});

	if (loading) return <Loading height="200px" />;
	if (error) return <p>Error: {error.message}</p>;

	const { gfForm } = data;

	return <GravityFormsDisplay data={gfForm} />;
};

export default GravityformsForm;
