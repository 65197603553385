import archive from './archive';
import page from './page';
import single from './single';

export default {
	page,
	single,
	archive,
	home: archive,
};
