import { create } from 'zustand';
import { GetPageQuery } from '../__generated__/graphql';
import { MenuItemProps } from '../types/Menu';
import { FooterCtaProps } from '../types/FooterSettings';
import { buildMenuTree } from '../support/buildMenuTree';
import { GfFormProps } from '../types/GravityForms';

export type SiteState = {
	menuItems: MenuItemProps[];
	footerMenuItems: GetPageQuery['footerMenuItems']['nodes'];
	seo: GetPageQuery['page']['seo'];
	phoneNumber: string;
	address: string;
	ctaText: string;
	ctaUrl: string;
	footerCta: FooterCtaProps;
	gform: GfFormProps;
	pageTitle: string;
};

export type SiteActions = {
	setMenuItems: (
		menuItems: GetPageQuery['primaryMenuItems']['nodes']
	) => void;
	setFooterMenuItems: (
		footerMenuItems: GetPageQuery['footerMenuItems']['nodes']
	) => void;
	setSeo: (seo: GetPageQuery['page']['seo']) => void;
	setFooterCta: (footerCta: FooterCtaProps) => void;
	setSitePhoneNumber: (val: string) => void;
	setSiteAddress: (val: string) => void;
	setCta: (text: string, url: string) => void;
	setGform: (data: GfFormProps) => void;
	setPageTitle: (title: string) => void;
};

export const useSiteStore = create<SiteState & SiteActions>(set => ({
	menuItems: [],

	footerMenuItems: [],

	seo: {} as GetPageQuery['page']['seo'],

	phoneNumber: '',

	pageTitle: '',

	address: '',

	ctaText: '',

	ctaUrl: '',

	footerCta: {} as FooterCtaProps,

	gform: {} as GfFormProps,

	setMenuItems: menuItems => {
		const tree = buildMenuTree(menuItems);
		set({ menuItems: tree });
	},

	setFooterMenuItems: footerMenuItems => set({ footerMenuItems }),

	setFooterCta: footerCta => set({ footerCta }),

	setSitePhoneNumber: (val: string) => set({ phoneNumber: val }),

	setSiteAddress: (val: string) => set({ address: val }),

	setSeo: (seo: GetPageQuery['page']['seo']) => set({ seo }),

	setCta: (text, url) => {
		set({ ctaText: text });
		set({ ctaUrl: url });
	},

	setGform: data => set({ gform: data }),

	setPageTitle: title => set({ pageTitle: title }),
}));
