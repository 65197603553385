import {
	Box,
	Button,
	Container,
	Heading,
	SimpleGrid,
	VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { GetArchiveQuery } from '../../__generated__/graphql';
import PostBanner from './components/PostBanner';
import PostCard from './components/PostCard';
import Image from 'next/image';
import { useBlocksTheme } from '@faustwp/blocks';
import { CBATheme } from '../../types/Theme';
import Link from 'next/link';
import { frontendLink } from '../../support/helpers';

const PostFeed: FC<{
	posts: GetArchiveQuery['posts']['nodes'];
	blogSettings: GetArchiveQuery['page']['blogSettings'];
}> = ({ posts, blogSettings }) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	return (
		<VStack align="stretch" position="relative">
			<Box
				position="absolute"
				bgGradient="linear(to-b, gray.200, transparent)"
				h="400px"
				left="0"
				right="0"
				zIndex="0"
			/>
			<PostBanner />

			<Container my={12} position="relative" zIndex="5">
				<SimpleGrid
					columns={{ base: 2, lg: 3 }}
					gap={{
						base: 4,
						lg: 8,
					}}
					px={{ base: 0, lg: 8 }}
				>
					{blogSettings.adImage?.node && (
						<Box
							overflow="hidden"
							sx={{
								img: {
									maxW: '100%',
								},
							}}
						>
							<Image
								src={blogSettings.adImage.node.sourceUrl}
								alt={blogSettings.adImage.node.altText}
								width={
									blogSettings.adImage.node.mediaDetails.width
								}
								height={
									blogSettings.adImage.node.mediaDetails
										.height
								}
								style={{
									borderRadius: '20px',
								}}
							/>
						</Box>
					)}
					{posts.map(post => (
						<PostCard key={post.id} post={post} />
					))}
					{blogSettings.ctaTitle && (
						<CtaBox
							title={blogSettings.ctaTitle}
							content={blogSettings.ctaContent}
							buttonLabel={blogSettings.ctaButtonLabel}
							buttonLink={blogSettings.ctaButtonUrl.nodes[0].link}
							display="desktop"
						/>
					)}
				</SimpleGrid>
				{blogSettings.ctaTitle && (
					<CtaBox
						title={blogSettings.ctaTitle}
						content={blogSettings.ctaContent}
						buttonLabel={blogSettings.ctaButtonLabel}
						buttonLink={blogSettings.ctaButtonUrl.nodes[0].link}
						display="mobile"
					/>
				)}
			</Container>
		</VStack>
	);
};

type CtaBoxProps = {
	title: string;
	content: string;
	buttonLink: string;
	buttonLabel: string;
	display: 'desktop' | 'mobile';
};

const CtaBox: FC<CtaBoxProps> = ({
	title,
	content,
	buttonLabel,
	buttonLink,
	display,
}) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	return (
		<VStack
			align="stretch"
			justifyContent="center"
			textAlign="center"
			border="3px"
			borderStyle="solid"
			borderRadius="20px"
			borderColor="#F69269"
			px={6}
			py={12}
			gap={8}
			display={
				display === 'desktop'
					? { base: 'none', lg: 'flex' }
					: { base: 'flex', lg: 'none' }
			}
			mt={display === 'mobile' ? 8 : 0}
		>
			<Heading as="h2" color={theme.palette.primary} fontSize={'30px'}>
				{title}
			</Heading>
			{content && <Box fontSize={'24px'}>{content}</Box>}
			<Box>
				<Button variant="cta" size="lg" as={Link} href={buttonLink}>
					{frontendLink(buttonLabel)}
				</Button>
			</Box>
		</VStack>
	);
};

export default PostFeed;
