import React, { FC } from 'react';
import { AcfLogoSlider as AcfLogoSliderProps } from '../../__generated__/graphql';
import { ImageProps } from '../../types/Image';
import { WordPressBlock } from '@faustwp/blocks';
import dynamic from 'next/dynamic';

const LogoSlider = dynamic(() => import('../../components/LogoSlider'));

const AcfLogoSlider: WordPressBlock<AcfLogoSliderProps> = props => {
	const logos = props.blockLogoSlider.logos.nodes as ImageProps[];

	return <LogoSlider logos={logos} />;
};

AcfLogoSlider.displayName = 'AcfLogoSlider';

export default AcfLogoSlider;
