// theme/components/formLabel.js
import { defineStyleConfig } from '@chakra-ui/react';

export const formLabelTheme = defineStyleConfig({
	baseStyle: {
		fontSize: 'sm',
		color: 'black',
		mb: 2,
	},
});
