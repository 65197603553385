import { extendTheme } from '@chakra-ui/react';
import { containerTheme } from './components/Container';
import {
	themeBreakpoints,
	themeColors,
	themeFontSizes,
	themeShadows,
} from './styles';
import { themeFonts } from './styles';
import { themeGlobal } from './styles';
import { buttonTheme } from './components/Button';
import { formLabelTheme } from './components/FormLabel';
import { inputTheme } from './components/Input';
import { checkboxTheme } from './components/Checkbox';
import { modalTheme } from './components/Modal';

export default extendTheme({
	breakpoints: themeBreakpoints,
	colors: themeColors,
	fonts: themeFonts,
	fontSizes: themeFontSizes,
	shadows: themeShadows,
	styles: {
		global: themeGlobal,
	},
	components: {
		Container: containerTheme,
		Button: buttonTheme,
		FormLabel: formLabelTheme,
		Input: inputTheme,
		Checkbox: checkboxTheme,
		Modal: modalTheme,
	},
});
