import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

// define custom sizes
const sizes = {
	sm: defineStyle({
		maxW: '45ch',
	}),
	md: defineStyle({
		maxW: '960px',
	}),
	lg: defineStyle({
		maxW: '1200px',
	}),
};

// export the component theme
export const containerTheme = defineStyleConfig({
	sizes: sizes,
	defaultProps: {
		size: 'lg',
	},
});
