import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { noto_sans, teko } from '../../fonts';

const cta = defineStyle({
	bg: 'blue.500',
	color: 'white',
	_hover: {
		bg: 'blue.600',
		'.chakra-button__icon': {
			transform: 'translateX(3px) translateY(-2px)',
		},
	},
});
const ctaOrange = defineStyle({
	bg: 'orange.500',
	color: 'white',
	_hover: {
		bg: 'orange.300',
		'.chakra-button__icon': {
			transform: 'translateX(3px) translateY(-2px)',
		},
	},
});
const footerCtaOrange = defineStyle({
	bg: 'orange.500',
	color: 'white',
	borderTopRadius: 16,
	borderBottomRadius: 0,
	py: 8,
	fontSize: 'x-large',
	_hover: {
		bg: 'orange.300',
		'.chakra-button__icon': {
			transform: 'translateX(3px) translateY(-2px)',
		},
	},
});

const orangeArrow = defineStyle({
	color: 'orange.500',
	px: 0,
	fontFamily: noto_sans.style.fontFamily,
	textTransform: 'none',
	fontSize: 'md',
	_hover: {
		color: 'black',
		'.chakra-button__icon': {
			transform: 'translateX(3px) translateY(-2px)',
			path: {
				fill: 'black',
			},
		},
	},
});

const blueArrow = defineStyle({
	color: 'blue.500',
	px: 0,
	fontFamily: noto_sans.style.fontFamily,
	textTransform: 'none',
	fontSize: 'md',
	w: '100%',
	display: 'block',
	_hover: {
		color: 'black',
		'.chakra-button__icon': {
			transform: 'translateX(3px) translateY(-2px)',
			path: {
				fill: 'black',
			},
		},
	},
});

const orangeOutline = defineStyle({
	color: 'orange.500',
	px: 0,
	fontFamily: noto_sans.style.fontFamily,
	textTransform: 'none',
	fontSize: 'md',
	w: '100%',
	display: 'block',
	borderWidth: '1px',
	borderColor: 'orange.500',
	borderStyle: 'solid',
	_hover: {
		borderColor: 'blackAlpha.800',
		color: 'blackAlpha.800',
	},
});

const radio = defineStyle({
	color: 'gray.400',
	fontFamily: noto_sans.style.fontFamily,
	textTransform: 'none',
	fontSize: 'sm',
	borderColor: 'gray.400',
	borderWidth: '1px',
	borderStyle: 'solid',
	rounded: 'none',
	flex: '1',
	_hover: {
		color: 'black',
	},
	_first: {
		borderRight: 'none',
	},
	_active: {
		bg: 'orange.500',
		color: 'white',
	},
});

const md = defineStyle({
	fontSize: 'lg',
	px: 6,
});

export const buttonTheme = defineStyleConfig({
	baseStyle: {
		fontFamily: teko.style.fontFamily,
		fontWeight: '400',
		fontSize: 'lg',
		px: 16,
		textTransform: 'uppercase',
		textDecor: 'none',
	},
	sizes: { md },
	variants: {
		cta,
		orangeArrow: orangeArrow,
		blueArrow: blueArrow,
		ctaOrange: ctaOrange,
		footerCtaOrange: footerCtaOrange,
		radio: radio,
		orangeOutline: orangeOutline,
	},
});
