import React, { FC } from 'react';
import { chakra } from '@chakra-ui/react';
const ButtonArrow: FC = props => {
	return (
		<chakra.svg
			width="16px"
			height="15px"
			viewBox="0 0 16 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.1498 6.58313H0.5V8.41687H14.1498V6.58313Z"
				fill="currentColor"
			/>
			<path
				d="M7.36195 15L6.10425 13.6614L12.7997 7.5L6.10425 1.33863L7.36195 0L15.5001 7.5L7.36195 15Z"
				fill="currentColor"
			/>
		</chakra.svg>
	);
};

export default ButtonArrow;
