import {
	Box,
	Button,
	HStack,
	Heading,
	Icon,
	Spacer,
	Stack,
	VStack,
} from '@chakra-ui/react';
import { useBlocksTheme } from '@faustwp/blocks';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { GetArchiveQuery } from '../../../__generated__/graphql';
import { formatDate, frontendLink } from '../../../support/helpers';
import { CBATheme } from '../../../types/Theme';
import ArrowRight from '../../Icons/components/ArrowRight';

const PostCard: FC<{ post: GetArchiveQuery['posts']['nodes'][number] }> = ({
	post,
}) => {
	const theme = useBlocksTheme() as unknown as CBATheme;
	return (
		<VStack
			align="stretch"
			bg={theme.palette.secondary_light}
			rounded="20px"
			overflow="hidden"
			gap={0}
			as={Link}
			href={frontendLink(post.link)}
			textDecor="none"
			role="group"
		>
			{post.featuredImage?.node && (
				<Box
					h={'150px'}
					overflow="hidden"
					sx={{
						img: {
							maxW: {
								base: '350px',
								lg: '100%',
							},
							transform: {
								base: 'translateX(-50%)',
								lg: 'none',
							},
							right: {
								base: '-50%',
								lg: 'auto',
							},
						},
					}}
				>
					<Image
						src={post.featuredImage.node.sourceUrl}
						alt={post.featuredImage.node.altText}
						width={post.featuredImage.node.mediaDetails.width}
						height={post.featuredImage.node.mediaDetails.height}
						style={{
							position: 'relative',
						}}
					/>
				</Box>
			)}
			<VStack
				p={{
					base: 3,
					lg: 6,
				}}
				align="stretch"
				gap={{
					base: 2,
					lg: 6,
				}}
			>
				<Heading
					as={'h2'}
					color={theme.palette.primary}
					fontSize={{
						base: theme.fontSizes.small,
						lg: theme.fontSizes.medium,
					}}
					transition="color 0.3s ease"
					_groupHover={{
						color: theme.palette.primary_dark,
					}}
				>
					{post.title}
				</Heading>
				<Box
					display={{
						base: 'none',
						lg: 'block',
					}}
					dangerouslySetInnerHTML={{ __html: post.excerpt }}
				/>
				<Stack
					direction={{
						base: 'column',
						lg: 'row',
					}}
				>
					<Box
						as="p"
						fontSize="sm"
						display={{
							base: 'none',
							lg: 'flex',
						}}
					>
						{formatDate(post.date)}
					</Box>
					<Spacer />
					<Button
						variant="ghost"
						color={theme.palette.secondary}
						rightIcon={
							<Icon
								as={ArrowRight}
								h={3}
								transform="translateY(-2px)"
								transition="all 0.3s ease"
							/>
						}
						h="auto"
						justifyContent="flex-start"
						px="0"
						sx={{
							'.chakra-button__icon': {
								transition: 'transform 0.3s ease',
								_groupHover: {
									transform: 'translateX(1px)',
								},
							},
						}}
						_hover={{
							bg: 'transparent',
							color: 'blackAlpha.800',
							'.chakra-button__icon': {
								path: {
									fill: 'blackAlpha.800',
								},
							},
						}}
						fontSize={{
							base: theme.fontSizes.small,
							lg: theme.fontSizes.medium,
						}}
					>
						Read More
					</Button>
				</Stack>
			</VStack>
		</VStack>
	);
};

export default PostCard;
