import React from 'react';
import { gql } from '@apollo/client';

import {
	WordPressBlock,
	WordPressBlocksViewer,
	getStyles,
	useBlocksTheme,
} from '@faustwp/blocks';

import { ContentBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import { Box } from '@chakra-ui/react';
import { propsObjectType } from '../../types/declarations';

export type CoreColumnFragmentProps = ContentBlock & {
	attributes?: {
		cssClassName?: string;
		align?: string;
		anchor?: string;
		layout?: string;
		verticalAlignment?: string;
		borderColor?: string;
		backgroundColor?: string;
		fontSize?: string;
		fontFamily?: string;
		style?: string;
		textColor?: string;
		gradient?: string;
		width: string;
	};
};

const CoreColumn: WordPressBlock<CoreColumnFragmentProps> = props => {
	const theme = useBlocksTheme();
	const style = getStyles(theme, { ...props });
	const { attributes, innerBlocks } = props;

	// console.log('Core Column', style, props);

	const columnProps = {} as propsObjectType;

	if (attributes.cssClassName.includes('is-style-big-spacing-left')) {
		columnProps.px = { base: 0, md: 12 };
		columnProps.textAlign = { base: 'center', md: 'left' };
	}

	if (attributes.cssClassName.includes('is-style-big-spacing-right')) {
		columnProps.px = { base: 0, md: 12 };
		columnProps.textAlign = { base: 'center', md: 'left' };
	}

	// if (!columnProps?.px) {
	// 	columnProps.px = { base: 4, md: 0 };
	// }

	return (
		<Box
			style={style}
			className={attributes?.cssClassName}
			{...columnProps}
			maxW={{
				base: '100%',
				lg: attributes.width,
			}}
		>
			<WordPressBlocksViewer blocks={innerBlocks ?? []} />
		</Box>
	);
};

CoreColumn.fragments = {
	key: `CoreColumnBlockFragments`,
	entry: gql`
		fragment CoreColumnBlockFragments on CoreColumn {
			attributes {
				anchor
				borderColor
				backgroundColor
				cssClassName
				fontSize
				fontFamily
				gradient
				layout
				style
				textColor
				verticalAlignment
				className
			}
			innerBlocks {
				renderedHtml
			}
		}
	`,
};
CoreColumn.config = {
	name: 'CoreColumn',
};
CoreColumn.displayName = 'CoreColumn';

export default CoreColumn;
