import React, { FC } from "react";
import { chakra } from "@chakra-ui/react";

const ArrowRight: FC = (props) => {
  return (
    <chakra.svg
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1497 7.08313H0.499878V8.91687H14.1497V7.08313Z"
        fill="#F26329"
      />
      <path
        d="M7.36183 15.5L6.10413 14.1614L12.7996 8L6.10413 1.83863L7.36183 0.5L15.4999 8L7.36183 15.5Z"
        fill="#F26329"
      />
    </chakra.svg>
  );
};

export default ArrowRight;
