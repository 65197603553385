import React, { FC } from 'react';
import { AcfProductIconDisplayProps } from '../../types/AcfProductIconDisplay';
import {
	Box,
	Button,
	HStack,
	Heading,
	Icon,
	Image as ChakraImage,
	Spacer,
} from '@chakra-ui/react';
import { useBlocksTheme } from '@faustwp/blocks';
import { CBATheme } from '../../types/Theme';
import ButtonArrow from '../Icons/components/ButtonArrow';
import Link from 'next/link';
import { frontendLink } from '../../support/helpers';
import Image from 'next/image';

const ProductIconDisplay: FC<AcfProductIconDisplayProps> = props => {
	const theme = useBlocksTheme() as unknown as CBATheme;
	return (
		<Box
			p={8}
			rounded="20px"
			bg="white"
			boxShadow={'lg'}
			position="relative"
			pt={20}
			mt={16}
			as={Link}
			href={frontendLink(props.link)}
			display="block"
			textDecoration="none"
			className="no-underline"
			role="group"
		>
			{props.icon && (
				<Box
					position="absolute"
					left="0"
					right="0"
					top="-44px"
					mx="auto"
					w="88px"
					h="88px"
				>
					<Image
						src={props.icon.sourceUrl}
						alt={props.icon.altText}
						width={88}
						height={88}
					/>
				</Box>
			)}
			<Button
				variant="blueArrow"
				fontWeight="600"
				fontSize={theme.fontSizes.medium}
				mb={4}
				textDecor="none"
				whiteSpace="none"
				as="h2"
			>
				<HStack>
					<Box>{props.title}</Box>
					<Spacer />
					<Icon
						as={ButtonArrow}
						transition="transform 0.3s ease"
						_groupHover={{
							transform: 'translateX(5px)',
						}}
					/>
				</HStack>
			</Button>
			<Box dangerouslySetInnerHTML={{ __html: props.content }} />
		</Box>
	);
};

export default ProductIconDisplay;
