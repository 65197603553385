import { gql } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import { WordPressBlock, getStyles, useBlocksTheme } from '@faustwp/blocks';
import { ContentBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import Image from 'next/image';
import { CBATheme } from '../../types/Theme';
import { propsObjectType } from '../../types/declarations';

export type CoreImageFragmentProps = ContentBlock & {
	attributes?: {
		align?: string;
		alt?: string;
		anchor?: string;
		borderColor?: string;
		className?: string;
		width?: number;
		id?: number;
		url?: string;
		title?: string;
		style?: string;
		src?: string;
		sizeSlug?: string;
		rel?: string;
		lock?: string;
		linkTarget?: string;
		linkDestination?: string;
		linkClass?: string;
		href?: string;
		height?: number;
		cssClassName?: string;
	};
	mediaDetails?: {
		height: number;
		width: number;
		sizes: Array<{
			mimeType: string;
			name: string;
			sourceUrl: string;
			width: string;
			height: string;
		}>;
	};
};

const CoreImage: WordPressBlock<CoreImageFragmentProps> = props => {
	const {
		attributes = {} as CoreImageFragmentProps['attributes'],
		mediaDetails = {} as CoreImageFragmentProps['mediaDetails'],
	} = props;
	const cbaTheme = useBlocksTheme() as unknown as CBATheme;
	const theme = useBlocksTheme();

	const styles = getStyles(theme, props);

	const isOrangeOutline = attributes.className?.includes('is-style-orange');

	const isOrangeTopLeft = attributes.className?.includes(
		'orange-border-top-left'
	);
	const isOrangeTopRight = attributes.className?.includes(
		'orange-border-top-right'
	);
	const isOrangeBottomRight = attributes.className?.includes(
		'orange-border-bottom-right'
	);

	const isSvg = attributes.src?.includes('.svg');

	function getSize(key: 'width' | 'height'): number {
		if (
			attributes &&
			attributes[key] !== undefined &&
			attributes[key] !== null
		) {
			const result = Number(String(attributes[key]).replace('px', ''));

			if (isNaN(result)) {
				const altSize = key === 'width' ? 'height' : 'width';
				const altResult = Number(
					String(attributes[altSize]).replace('px', '')
				);

				if (
					altResult &&
					mediaDetails[key] !== undefined &&
					mediaDetails[altSize] !== undefined
				) {
					// Calculate ratio from media details
					const ratio = mediaDetails[key] / mediaDetails[altSize];

					if (!isNaN(ratio)) {
						return Math.ceil(altResult * ratio);
					}
				}

				console.log('Alt Result', altResult);

				return 0;
			}

			return result;
		} else if (mediaDetails && mediaDetails[key] !== undefined) {
			return mediaDetails[key] as number;
		}
		return 0;
	}

	const imgWidth = getSize('width');
	const imgHeight = getSize('height');

	if (isOrangeOutline) {
		styles.borderRadius = '20px';
	}

	const borderProps: propsObjectType = {};

	if (isOrangeTopLeft) {
		borderProps.top = { base: 2, lg: -4 };
		borderProps.left = -4;
	}

	if (isOrangeTopRight) {
		borderProps.top = -4;
		borderProps.right = -4;
	}

	if (isOrangeBottomRight) {
		borderProps.top = { base: 2, lg: 4 };
		borderProps.left = { base: 2, lg: 4 };
	}

	return (
		<Box
			as={'figure'}
			id={attributes?.anchor ?? undefined}
			className={attributes?.cssClassName}
			position="relative"
			display="inline-block"
			mx="auto"
			marginBlockStart={{
				base: 8,
				md: 0,
			}}
			marginBlockEnd={{
				base: 0,
				md: '-6px!important',
			}}
			w={`${imgWidth}px`}
			h={{
				base: 'auto',
				md: `${imgHeight}px`,
			}}
			maxW="100%"
			sx={{
				img: {
					height: 'auto!important',
				},
			}}
		>
			{isOrangeOutline && (
				<Box
					position="absolute"
					borderRadius="20px"
					borderColor={cbaTheme.palette.secondary}
					borderWidth={{ base: '2px', lg: '3px' }}
					w="100%"
					h="100%"
					{...borderProps}
					opacity={0.6}
				/>
			)}
			<Image
				src={attributes.src!}
				alt={attributes.alt!}
				style={styles}
				width={imgWidth}
				height={imgHeight}
				loading={
					attributes.cssClassName?.includes('no-lazy')
						? 'eager'
						: 'lazy'
				}
				priority={attributes.cssClassName?.includes('no-lazy')}
			/>
		</Box>
	);
};

CoreImage.fragments = {
	entry: gql(`
      fragment CoreImageBlockFragment on CoreImage {
			attributes {
				align
				alt
				anchor
				borderColor
				className
				width
				url
				title
				style
				src
				sizeSlug
				rel
				lock
				linkTarget
				linkDestination
				linkClass
				href
				height
				cssClassName
			}
			mediaDetails {
				width
				height
				sizes {
					mimeType
					name
					sourceUrl
					width
					height
				}
			}
		}
    `),
	key: `CoreImageBlockFragment`,
};

CoreImage.displayName = 'CoreImage';

export default CoreImage;
