import { Box, Container, SimpleGrid, Stack } from '@chakra-ui/react';
import {
	WordPressBlock,
	WordPressBlocksViewer,
	getStyles,
	useBlocksTheme,
} from '@faustwp/blocks';
import { FC } from 'react';
import { gql } from '../../__generated__';
import {
	WordPressBlockViewerFix,
	propsObjectType,
} from '../../types/declarations';

const CoreGroup: WordPressBlock<WordPressBlockViewerFix> = props => {
	const theme = useBlocksTheme();
	const style = getStyles(theme, { ...props });

	const styleAttribute = props.attributes.style
		? JSON.parse(props.attributes.style)
		: { type: 'default' };

	if (!props.innerBlocks) {
		return <>Group has no blocks</>;
	}

	// console.log('Group Props', props);

	const layout = props.attributes.layout
		? JSON.parse(props.attributes.layout)
		: { type: 'default' };

	// Add the `as` prop to the Box component
	let AsComponent: FC = Box;

	let additionalProps: propsObjectType = {};

	if (layout.type === 'constrained') {
		AsComponent = Container;
	}

	if (layout.type === 'flex' && layout?.flexWrap !== 'wrap') {
		AsComponent = Stack;
		additionalProps.direction = {
			base: 'column',
			lg: 'row',
		};
	}

	if (layout?.flexWrap === 'wrap') {
		AsComponent = SimpleGrid;
		additionalProps.columns = { base: 1, md: 2, lg: 3 };
		additionalProps.spacing = 4;
		additionalProps.gap = 8;
	}

	if (styleAttribute?.spacing?.blockGap) {
		// console.log('Block Gap', styleAttribute?.spacing?.blockGap);
		// Get only numbers from string styleAttribute?.spacing?.blockGap
		const blockGap = styleAttribute.spacing.blockGap.replace(/\D/g, '');
		additionalProps.gap = (blockGap / 10) * 3;
	}

	// console.log('Group Classes', props.attributes);

	if (
		props.attributes?.className &&
		props.attributes.className === 'is-style-bg-box-shadow'
	) {
		additionalProps.boxShadow = 'md';
	}
	return (
		<Box
			as={AsComponent}
			style={style}
			className={props.attributes?.className}
			{...additionalProps}
			id={props.attributes.anchor}
			sx={{
				ul: {
					pl: 8,
					my: 4,
					li: {
						my: 2,
					},
				},
				'a:not(.chakra-button):not(.no-underline)': {
					textDecoration: 'underline',
				},
			}}
		>
			<WordPressBlocksViewer blocks={props.innerBlocks ?? []} />
		</Box>
	);
};

CoreGroup.fragments = {
	entry: gql(`
      fragment CoreGroupBlockFragment on CoreGroup {
        cssClassNames
        attributes {
          className
          style
          backgroundColor
          borderColor
          textColor
          fontSize
          fontFamily
          layout
          metadata
          tagName
          gradient
          lock
          anchor
          allowedBlocks
          align
        }
        innerBlocks {
          renderedHtml
        }
      }
    `),
	key: `CoreGroupBlockFragment`,
};

CoreGroup.displayName = 'CoreGroup';

export default CoreGroup;
