import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Container,
	Heading,
} from '@chakra-ui/react';
import { WordPressBlock, useBlocksTheme } from '@faustwp/blocks';
import { AccordionDisplayProps } from '../../components/AccordionDisplay';
import { pageStyles } from '../../theme/pageStyles';
import { CBATheme } from '../../types/Theme';

type AcfAccordionDisplayProps = {
	blockAccordionDisplay: AccordionDisplayProps;
};

const AcfAccordionDisplay: WordPressBlock<AcfAccordionDisplayProps> = props => {
	const accordions = props?.blockAccordionDisplay?.accordions;
	const theme = useBlocksTheme() as unknown as CBATheme;

	if (!props?.blockAccordionDisplay?.accordions) {
		return <></>;
	}

	return (
		<Container maxW="866px" px={0}>
			<Accordion allowMultiple={true}>
				{accordions.map((accordion, i) => (
					<AccordionItem key={i}>
						<Heading
							as="h3"
							fontSize={theme.fontSizes.large}
							color={theme.palette.primary}
							fontWeight={'bold'}
							py={3}
							px={{
								base: 4,
								lg: 12,
							}}
						>
							<AccordionButton>
								<Box as="strong" flex="1" textAlign="left">
									{accordion.title}
								</Box>
								<AccordionIcon />
							</AccordionButton>
						</Heading>
						<AccordionPanel
							pb={4}
							px={{
								base: 4,
								lg: 24,
							}}
						>
							<Box
								dangerouslySetInnerHTML={{
									__html: accordion.content,
								}}
								sx={pageStyles}
								fontSize={theme.fontSizes.small}
							/>
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>
		</Container>
	);
};

AcfAccordionDisplay.displayName = 'AcfAccordionDisplay';

export default AcfAccordionDisplay;
