import React, { FC } from 'react';
import { gql } from '@apollo/client';

import { WordPressBlock, getStyles } from '@faustwp/blocks';
import { useBlocksTheme } from '@faustwp/blocks';

import { WordPressBlocksViewer } from '@faustwp/blocks';
import { ContentBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { propsObjectType } from '../../types/declarations';

export type CoreButtonsFragmentProps = ContentBlock & {
	attributes?: {
		cssClassName?: string;
		align?: string;
		anchor?: string;
		layout?: string;
		fontSize?: string;
		fontFamily?: string;
		style?: string;
	};
};

const CoreButtons: WordPressBlock<CoreButtonsFragmentProps> = props => {
	const theme = useBlocksTheme();
	const style = getStyles(theme, props);

	const { attributes, innerBlocks } = props;

	const layout = props.attributes.layout
		? JSON.parse(props.attributes.layout)
		: { type: 'default' };

	const styleAttribute = props.attributes.style
		? JSON.parse(props.attributes.style)
		: { type: 'default' };

	// console.log('Button Group', styleAttribute, props);

	let additionalProps: propsObjectType = {};

	// Add the `as` prop to the Box component
	let AsComponent: FC = Flex;

	if (layout?.justifyContent) {
		additionalProps.justifyContent = layout.justifyContent;
	}

	if (layout?.orientation === 'vertical') {
		AsComponent = Stack;

		additionalProps.direction = {
			base: 'column',
			lg: 'column',
		};
		additionalProps.justifyContent = 'flex-start';
	}

	if (styleAttribute?.spacing?.blockGap) {
		// Get only numbers from string styleAttribute?.spacing?.blockGap
		const blockGap = styleAttribute.spacing.blockGap.replace(/\D/g, '');
		additionalProps.gap = (blockGap / 10) * 3;
	}

	return (
		<Box
			as={AsComponent}
			style={style}
			id={attributes?.anchor}
			className={attributes?.cssClassName}
			{...additionalProps}
		>
			<WordPressBlocksViewer blocks={innerBlocks ?? []} />
		</Box>
	);
};

CoreButtons.fragments = {
	key: `CoreButtonsBlockFragment`,
	entry: gql`
		fragment CoreButtonsBlockFragment on CoreButtons {
			attributes {
				className
				align
				anchor
				fontFamily
				fontSize
				layout
				style
			}
		}
	`,
};

CoreButtons.config = {
	name: 'CoreButtons',
};
CoreButtons.displayName = 'CoreButtons';

export default CoreButtons;
