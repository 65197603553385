import { noto_sans, teko } from '../fonts';

export const themeGlobal = {
	body: {
		fontSize: '17px',
		color: 'gray.600',
	},
	':focus, :focus-visible, *[data-focus]': {
		borderColor: 'transparent',
		outline: 'none',
		boxShadow: 'none',
	},
};

export const themeColors = {
	blue: {
		500: '#283891',
	},
	gray: {
		600: '#6F6D6E',
	},
	orange: {
		300: '#F89621',
		500: '#F26329',
	},
};

export const themeShadows = {
	sm: '0 0px 20px 0px rgba(0, 0, 0, 0.1)',
	md: '0 0px 20px -1px rgba(43, 56, 143, 0.25)',
};

export const themeFonts = {
	body: noto_sans.style.fontFamily,
	heading: noto_sans.style.fontFamily,
	alt: teko.style.fontFamily,
};

export const themeFontSizes = {
	md: '18px',
	lg: '22px',
};

export const themeBreakpoints = {
	base: '0px',
	sm: '320px',
	md: '768px',
	lg: '960px',
	xl: '1200px',
	'2xl': '1536px',
};
