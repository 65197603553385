import { Box, HStack } from '@chakra-ui/react';
import { useBlocksTheme } from '@faustwp/blocks';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { FC, useEffect, useState } from 'react';
import { CountupDisplayProps } from '../../types/CountUpDisplay';
import { CBATheme } from '../../types/Theme';

const CountupDisplay: FC<CountupDisplayProps> = ({
	string,
	number,
	smallFontSizeForString,
	fontColor,
	isStringBeforeText,
	noCount = false,
}) => {
	const theme = useBlocksTheme() as unknown as CBATheme;

	const [count, setCount] = useState<number>(0);
	const threshold = Number(number);

	const [ref, entry] = useIntersectionObserver({
		threshold: 0,
		root: null,
		rootMargin: '0px',
	});

	const calculateStepSize = (threshold: number) => {
		if (threshold <= 10) return 1;
		if (threshold <= 100) return 5;
		if (threshold <= 500) return 10;
		if (threshold <= 100) return 20;
		if (threshold <= 500) return 50;
		if (threshold <= 1000) return 100;
		if (threshold <= 5000) return 500;
		if (threshold <= 10000) return 1000;
		if (threshold <= 50000) return 15000;
		if (threshold <= 100000) return 40000;
		return 50000; // For threshold > 500
	};

	const stepSize = calculateStepSize(threshold);

	useEffect(() => {
		if (count < threshold && entry?.isIntersecting) {
			const timer = setTimeout(() => {
				setCount(prevCount =>
					Math.min(prevCount + stepSize, threshold)
				);
			}, 100);

			return () => clearTimeout(timer);
		}
	}, [number, count, threshold, stepSize, entry?.isIntersecting]);

	const colors: { [key: string]: string } = {
		blue: theme.palette.primary,
		orange: 'var(--wp--preset--color--secondary-alt)',
	};

	let headingColor = colors.blue;

	if (fontColor && fontColor.length > 0 && fontColor[0] in colors) {
		headingColor = colors[fontColor[0]];
	}

	return (
		<Box
			color={headingColor}
			fontFamily={theme.fontFamilies.body}
			fontSize={theme.fontSizes.xxxLarge}
			ref={ref}
			fontWeight="bold"
		>
			<HStack
				align={{
					base: 'center',
					md: 'flex-end',
				}}
				justify={{
					base: 'center',
					md: 'flex-start',
				}}
				gap={2}
				lineHeight={!smallFontSizeForString && '1.0'}
			>
				{entry?.isIntersecting && !noCount && (
					<Box
						order={isStringBeforeText ? 5 : 0}
						fontSize={{
							base: theme.fontSizes.xxLarge,
							lg: '3.94rem',
						}}
					>
						{count.toLocaleString()}
					</Box>
				)}
				{noCount && (
					<Box
						order={isStringBeforeText ? 5 : 0}
						fontSize={{
							base: theme.fontSizes.xxLarge,
							lg: '3.94rem',
						}}
					>
						{number.toLocaleString()}
					</Box>
				)}
				<Box
					fontSize={{
						base: smallFontSizeForString
							? theme.fontSizes.medium
							: theme.fontSizes.xxLarge,
						lg: smallFontSizeForString ? '2.35rem' : '3.94rem',
					}}
					lineHeight={smallFontSizeForString ? '1.5' : '1.0'}
					dangerouslySetInnerHTML={{ __html: string }}
				/>
			</HStack>
		</Box>
	);
};

export default CountupDisplay;
