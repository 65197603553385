import { gql } from '@apollo/client';

import { Button, Icon } from '@chakra-ui/react';
import { WordPressBlock } from '@faustwp/blocks';
import { ContentBlock } from '@faustwp/blocks/dist/mjs/components/WordPressBlocksViewer';
import Link from 'next/link';
import ArrowRight from '../../components/Icons/components/ArrowRight';
import ButtonArrow from '../../components/Icons/components/ButtonArrow';
import { propsObjectType } from '../../types/declarations';

export type CoreButtonFragmentProps = ContentBlock & {
	attributes?: {
		anchor?: string;
		backgroundColor?: string;
		cssClassName?: string;
		fontFamily?: string;
		fontSize?: string;
		gradient?: string;
		style?: string;
		text?: string;
		textAlign?: string;
		textColor?: string;
		linkTarget?: string;
		rel?: string;
		url?: string;
		className?: string;
	};
};

const getBtnVariant = (className: string) => {
	switch (className) {
		case 'is-style-ghost-orange-arrow':
			return 'orangeArrow';
		case 'is-style-ghost-blue-arrow':
			return 'blueArrow';
		case 'cta':
			return 'cta';
		case 'is-style-orange-arrow-button':
			return 'ctaOrange';
		case 'is-style-footer-cta-orange':
			return 'footerCtaOrange';
		case 'is-style-arrow-button':
			return 'cta';
		default:
			return 'orangeArrow';
	}
};

const CoreButton: WordPressBlock<CoreButtonFragmentProps> = props => {
	const { attributes } = props;

	const icons: { [key: string]: any } = {
		'is-style-ghost-orange-arrow': <Icon as={ArrowRight} />,
		'is-style-ghost-blue-arrow': <Icon as={ButtonArrow} />,
		'is-style-arrow-button': <Icon as={ButtonArrow} />,
		'is-style-orange-arrow-button': <Icon as={ButtonArrow} />,
	};

	const btnProps: propsObjectType = {
		variant: getBtnVariant(attributes.className),
		rightIcon:
			attributes.className in icons ? icons[attributes.className] : null,
	};

	if (attributes.url) {
		btnProps.as = Link;
		btnProps.href = attributes.url;
	}

	return (
		<Button
			{...btnProps}
			sx={{
				'.chakra-button__icon': {
					transition: 'color, transform 0.3s ease',
					transform: 'translateY(-2px)',
				},
			}}
		>
			{attributes.text}
		</Button>
	);
};

CoreButton.fragments = {
	key: `CoreButtonBlockFragment`,
	entry: gql`
		fragment CoreButtonBlockFragment on CoreButton {
			attributes {
				anchor
				gradient
				textAlign
				textColor
				style
				fontSize
				fontFamily
				linkTarget
				rel
				url
				backgroundColor
				className
			}
		}
	`,
};
CoreButton.config = {
	name: 'CoreButton',
};
CoreButton.displayName = 'CoreButton';

export default CoreButton;
